@import '../../scss/mixins';
@import '../../scss/variables';

.container-cui-404 {
  width: 100vw;
  height: 100vh;
  background-color: $cookunity-black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .title {
    max-width: 580px;
    color: #fff;
    text-align: center;
    font-family: $cui-font-family-source-serif;
    font-size: 40px;
    line-height: 48px;
    font-weight: $cui-font-bold;
    @include tablet {
      font-size: 64px;
      line-height: 76px;
    }
  }

  .btn {
    width: 232px;
    padding: 14px 32px;
    height: 48px;

    p {
      color: #000000;
    }
  }
}

@import '../../scss/mixins';
@import '../../scss/cui/variables';

.rebranding {
  .cookunity__settings_card {
    .cookunity__remove_setting {
      .cookunity__remove_label {
        color: $cui-black-color;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-base,
          $cui-line-height-xl,
          $cui-font-bold
        );
      }

      .label {
        color: $cui-black-color;
      }
    }
  }
}

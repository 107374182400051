@import '../../../../../../scss/mixins';
@import '../../../../../../scss/cui/variables';

.flex-mode__confirmation-modal__modal-content {
  @include desktop {
    padding: 2.5rem !important;
  }
}

.ReactModal__Content {
  padding-block-end: 1rem;

  .flex-mode__confirmation-modal__close-icon {
    @include desktop {
      margin: 0.25rem 1rem;
    }

    path {
      fill: $cui-black-color;
    }

    &-bottom-sheet {
      top: 1rem;
      right: 1rem;
      position: absolute;
    }
  }

  .flex-mode__confirmation-modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-block-start: 1rem;

    @include desktop {
      margin-block-start: 0;
    }

    .flex-mode__confirmation-modal__title-container {
      .flex-mode__confirmation-modal__title {
        margin: 0;

        text-align: center;
        white-space: pre-wrap;

        color: $cui-black-color-500;
        @include font-build(
          $cui-font-family-source-serif,
          $cui-font-2xl,
          $cui-line-height-4xl,
          $cui-font-bold
        );

        @include desktop {
          font-size: $cui-font-3xl;
        }
      }
    }

    .flex-mode__confirmation-modal__text-container {
      margin-block-start: 0.5rem;

      @include desktop {
        margin-block-start: 1rem;
      }

      .flex-mode__confirmation-modal__text {
        margin: 0;

        text-align: center;
        white-space: pre-wrap;

        color: $cui-black-color-500;
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-xl,
          $cui-font-normal
        );

        @include desktop {
          font-size: $cui-font-base;
          line-height: $cui-line-height-2xl;
        }
      }
    }

    .flex-mode__confirmation-modal__buttons-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      gap: 1.5rem;
      margin-block-start: 2.5rem;

      @include desktop {
        flex-direction: row;
        margin-block-start: 1.75rem;
      }
    }
  }
}

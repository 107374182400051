@import '../../scss/mixins';
@import '../../scss/cui/variables';

@keyframes delayDisplay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.hero-cui {
  background-color: #000;
  background-position: left;
  background-size: cover;
  position: relative;
  // background-image: url('/assets/images/default-hero.jpg');

  .container-cui {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
    padding: 124px 0 124px;

    @media (max-width: 920px) {
      padding: 82px 20px;
    }
  }

  .half-container-row {
    display: flex;
    width: 100%;
    max-width: 1440px;
    height: 462px;
    justify-content: space-between;
    margin: 0 auto;

    @media (max-width: 920px) {
      flex-direction: column-reverse;
      height: 100%;
      margin: 0;
    }

    .container-cui {
      max-width: 50%;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;

      @media (max-width: 920px) {
        max-width: 100%;
        padding: 35px 20px;
      }
    }
  }

  .content-left {
    max-width: 530px;
    margin: 0;
    text-align: left;
    margin-left: 98px;
    position: relative;

    @media (max-width: 920px) {
      max-width: 100%;
      padding-top: 0px;
      margin: 0 auto;
      text-align: center;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media (min-width: 920px) and (max-width: 1200px) {
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    img {
      width: unset;
    }

    h1.rebrand {
      margin: 0;
      color: $cui-white-color;
      font-family: $cui-font-family-rossanova;
      font-weight: $cui-font-bold;
      font-size: $cui-font-8xl;
      line-height: 68px;

      @include mobile {
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 20px;
      }

      span {
        color: $cui-yellow-color-500;
        font-family: $cui-font-family-avallon;
        font-weight: $cui-font-normal;
        display: inline;
        font-size: 90px;
        line-height: 68px;

        @include mobile {
          font-size: 50px;
          line-height: 44px;
        }
      }
    }

    p.rebrand {
      color: $cui-white-color;
      font-family: $cui-font-family-tt-norms;
      font-size: $cui-font-base;
      line-height: $cui-line-height-2xl;
      margin: 0px;
      margin-bottom: 36px;
    }

    p.rebrand.small {
      color: #4d4d4f;
      margin-top: 12px;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 0;
    }

    h2.rebrand {
      color: $cui-white-color;
      font-family: $cui-font-family-source-serif;
      font-weight: $cui-font-bold;
      font-size: $cui-font-3xl;
      line-height: $cui-line-height-4xl;
      padding: 0;
      margin: 0;
      margin-bottom: 12px;
      width: 100%;
      text-align: left;

      @include mobile {
        margin-bottom: 20px;
        text-align: center;
      }
    }
  }

  &.no-background {
    background-image: none;
    padding: 0;
    overflow: hidden;
  }

  .half-container-image {
    max-width: 653px;
    width: 100%;
    height: 100%;

    @media (max-width: 920px) {
      max-width: 100%;
      height: 303px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  img {
    width: 100%;
  }
}

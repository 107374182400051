.heading-1 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 44px;
}

.heading-2 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
}

.heading-3 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
}

.heading-4 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 18px;
}

.heading-5 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
}

.heading-6 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;
}

.heading-7 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
}

.heading-8 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
}

.heading-9 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 36px;
}

.heading-10 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
}

.paragraph-1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.paragraph-2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
}

.paragraph-3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
}

.paragraph-4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
}

.paragraph-5 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
}

.paragraph-6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.paragraph-7 {
  font-family: Gilroy;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 25px;
}

.paragraph-8 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
}

.caption {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
}

.bold {
  font-weight: bold;
}

@import '../../../scss/mixins';
@import '../../../scss/variables';

.cookunity__input_radio_container {
  display: inline-block;
  margin-right: 10px;

  .cookunity__input_radio {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid $cookunity-secondary-dark-green;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    .checkmark {
      // display: none;
      width: 10px;
      height: 10px;
      background-color: $cookunity-secondary-dark-green;
      border-radius: 50%;
      opacity: 0;
    }

    &.checked {
      .checkmark {
        opacity: 1;
      }
    }

    &[type='radio'] {
      display: none;
    }
  }
}

.cookunity__input_field {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;

  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 16px;

  &[type='checkbox'] + .cookunity__form_label,
  &[type='radio'] + .cookunity__form_label {
    cursor: pointer;
  }

  &[type='checkbox'] {
    display: none;
    width: 0;
  }

  &[type='radio'] {
    display: none;
  }
}

@import '../../scss/mixins';
@import '../../scss/variables';
@import '../../scss/cui/variables';

.cookunity__my_plan_section {
  .title_container {
    margin-bottom: 6px;
    .title {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.cookunity__plan_delivery_info {
  padding-top: 10px;

  & > .title {
    font-family: Gilroy, Roboto, sans-serif;
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 40px 0;
  }

  & > .subtitle {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 32px 0;
  }

  .plan_delivery_info_row {
    width: 70%;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}

.cookunity__change_plan_section {
  font-family: Gilroy, Roboto, sans-serif;
  color: $cookunity-black;

  & > .change_plan_breadcrumb {
    margin-bottom: 24px;

    @include mobile {
      display: none;
    }
  }

  & > .title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 40px 0;
  }

  .plans_container {
    margin-bottom: 40px;

    .plan {
      display: flex;
      align-items: center;
      padding-bottom: 26px;

      &:not(:last-child) {
        margin-bottom: 24px;
        border-bottom: 1px solid $cookunity-gray-3;
      }

      .radio_button {
        padding-top: 5px;
        align-self: flex-start;
      }

      .plan_info {
        flex-grow: 1;
        cursor: pointer;
        .name {
          font-size: 16px;
          line-height: 24px;
          margin: 0 0 6px 0;

          .title_new_plan_meals {
            font-weight: 800;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.2px;
          }

          .title_new_plan_week {
            font-family: Roboto;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: $cookunity-gray-2;
          }
        }

        .description {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          line-height: 22px;
          margin: 0;

          .subtitle_new_plan_delivery {
            line-height: 24px;
            letter-spacing: 0.2px;
          }

          .subtitle_new_plan_price {
            line-height: 24px;
            letter-spacing: 0.2px;
            font-weight: bold;
          }
        }
      }

      .plan_price {
        text-align: right;
        cursor: pointer;
        .amount {
          font-size: 20px;
          line-height: 28px;
          margin: 0 0 6px 0;
        }

        .new_plan {
          @include mobile {
            font-weight: 800;
            line-height: 24px;
          }
        }

        .taxes {
          font-family: Roboto, sans-serif;
          font-size: 12px;
          line-height: 18px;
          margin: 0;
        }
      }
    }
  }
}

.cancel_subscription {
  text-align: right;
  & > span {
    font-family: Gilroy, Roboto, sans-serif;
    color: #231f20;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    cursor: pointer;
  }

  @include mobile {
    padding: 0 20px;
    margin-top: -30px;
    margin-bottom: 60px;
  }
}

.rebranding {
  .cookunity__account_settings_container {
    display: flex;
    flex-direction: column;
    max-width: 1296px;
    margin: auto;

    @include mobile {
      margin: 0;
    }

    .cookunity__account_settings_header {
      margin: 60px 0 40px 0;
      padding-inline: 40px;

      .title {
        margin: 0 0 8px 0;
        color: $cui-black-color;

        @include font-build(
          $cui-font-family-source-serif,
          $cui-font-4xl,
          $cui-line-height-5xl,
          $cui-font-bold
        );
      }

      .subtitle {
        margin: 0;
        color: $cui-black-color;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-base,
          $cui-font-normal
        );

        .change_plan {
          display: none;
        }

        strong {
          font-weight: $cui-font-normal;
        }
      }

      > .plan-tag-container {
        display: flex;
        align-items: center;
        margin-top: 1rem;

        .cui-meal-info {
          height: 15px;
          opacity: 0.8;
          vertical-align: middle;
          cursor: pointer;
        }

        .plan-info {
          display: flex;
        }

        .expire-date {
          color: $cui-black-color-400;
          margin-left: 10px;
          margin-right: 6px;
          @include font-build(
            $cui-font-family-tt-norms,
            $cui-font-xxs,
            $cui-line-height-xs,
            $cui-font-normal
          );
        }
      }
    }

    .cookunity__account_settings {
      display: block;
      padding-inline: 40px;
      margin-block-end: 60px;

      @include mobile {
        padding: 0;
      }

      .cookunity__setting_view {
        .setting_view_header {
          @include mobile {
            position: absolute;
            z-index: 1;
          }
        }

        .cookunity__settings_card {
          padding-block: 1.75rem;

          @include mobile {
            padding-inline: 1.75rem;
          }

          > .title {
            margin-block-start: 0;

            color: $cui-black-color;

            @include font-build(
              $cui-font-family-source-serif,
              $cui-font-3xl,
              $cui-line-height-4xl,
              $cui-font-bold
            );

            @include mobile {
              margin-bottom: 0.5rem;
            }
          }

          > .change-plan-title {
            color: $cui-black-color;

            @include font-build(
              $cui-font-family-source-serif,
              $cui-font-xl,
              $cui-line-height-3xl,
              $cui-font-bold
            );

            @include mobile {
              display: none;
            }
          }

          > .plan-tag-container {
            display: flex;
            align-items: center;
            margin-block: 1rem;

            .cui-meal-info {
              height: 15px;
              opacity: 0.8;
              vertical-align: middle;
              cursor: pointer;
            }

            .plan-info {
              display: flex;
            }

            .expire-date {
              color: $cui-black-color-400;
              margin-left: 10px;
              margin-right: 6px;
              @include font-build(
                $cui-font-family-tt-norms,
                $cui-font-xxs,
                $cui-line-height-xs,
                $cui-font-normal
              );
            }
          }

          .cookunity__my_plan_section {
            padding-bottom: 1.75rem;
            border-bottom: 1px solid $cui-black-color-200;

            @include mobile {
              padding-bottom: 1.25rem;
            }

            .cookunity__setting_section {
              .rows_container {
                .section_row {
                  .row_info {
                    .title_container {
                      @include mobile {
                        margin-bottom: 0;
                      }

                      .title {
                        @include mobile {
                          display: block;
                        }

                        .title_new_plan_meals {
                          color: $cui-black-color;

                          @include font-build(
                            $cui-font-family-source-serif,
                            $cui-font-xl,
                            $cui-line-height-2xl,
                            $cui-font-bold
                          );
                        }

                        .title_new_plan_meals_week {
                          color: $cui-black-color-300;

                          @include font-build(
                            $cui-font-family-tt-norms,
                            $cui-font-base,
                            $cui-line-height-xl,
                            $cui-font-normal
                          );
                        }

                        .title_new_plan_week {
                          color: $cui-black-color-300;

                          @include font-build(
                            $cui-font-family-tt-norms,
                            $cui-font-base,
                            $cui-line-height-xl,
                            $cui-font-normal
                          );
                        }
                      }
                    }

                    .subtitle {
                      margin-top: 0;

                      span {
                        color: $cui-black-color;

                        @include font-build(
                          $cui-font-family-tt-norms,
                          $cui-font-sm,
                          $cui-line-height-base,
                          $cui-font-bold
                        );

                        .subtitle_new_plan_delivery {
                          font-weight: $cui-font-normal;
                        }
                      }
                    }
                  }
                }
              }
            }

            .cookunity__action_button_container {
              @include mobile {
                margin-top: 1.5rem;
              }

              button {
                @include mobile {
                  width: 100%;
                }
              }
            }
          }

          .cookunity__my_account_form {
            margin-bottom: 0;

            .cookunity__form_label {
              color: $cui-black-color;

              @include font-build(
                $cui-font-family-tt-norms,
                $cui-font-sm,
                $cui-line-height-2xl,
                $cui-font-normal
              );
            }

            .cookunity__form_grid {
              margin-bottom: 0;

              @include mobile {
                margin-top: 0.5rem;
              }
            }

            .cookunity__button_row {
              margin-top: 1.25rem;
              margin-bottom: 0.25rem;

              @include mobile {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                margin-top: 1.75rem;
              }
            }
          }

          .cookunity__setting-section-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            margin-top: 1.75rem;

            @include mobile {
              flex-direction: column;
              margin-top: 1.875rem;
            }

            .cookunity__setting-section-text-container {
              display: flex;
              flex-direction: column;
              justify-content: center;

              .cookunity__setting-section-title {
                color: $cui-black-color;

                @include font-build(
                  $cui-font-family-tt-norms,
                  $cui-font-base,
                  $cui-line-height-2xl,
                  $cui-font-normal
                );

                @include mobile {
                  margin-bottom: 1.5rem;
                  font-size: $cui-font-sm;
                  line-height: $cui-line-height-xl;
                }
              }

              .cookunity__setting-section-subtitle {
                color: $cui-black-color;

                @include font-build(
                  $cui-font-family-tt-norms,
                  $cui-font-base,
                  $cui-line-height-2xl,
                  $cui-font-normal
                );

                @include mobile {
                  font-size: $cui-font-sm;
                  line-height: $cui-line-height-xl;
                }
              }
            }

            .cookunity__setting-section-buttons-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 1rem;

              @include mobile {
                justify-content: flex-end;
                margin-top: 1.125rem;
              }

              .cookunity__setting-section-buttons-pause {
                border: none;

                color: $cui-black-color;
                background-color: transparent;
                text-decoration: underline;
                cursor: pointer;

                @include font-build(
                  $cui-font-family-tt-norms,
                  $cui-font-base,
                  $cui-line-height-2xl,
                  $cui-font-bold
                );

                @include mobile {
                  font-size: $cui-font-sm;
                  line-height: $cui-line-height-xl;
                }
              }

              .cancel_subscription {
                span {
                  color: $cui-black-color;

                  @include font-build(
                    $cui-font-family-tt-norms,
                    $cui-font-base,
                    $cui-line-height-2xl,
                    $cui-font-bold
                  );

                  @include mobile {
                    font-size: $cui-font-sm;
                    line-height: $cui-line-height-xl;
                  }
                }

                @include mobile {
                  margin: 0;
                }
              }
            }
          }
        }

        .setting_view_header + .cookunity__settings_card {
          @include mobile {
            padding-top: 3.5rem;
          }
        }
      }

      .alert {
        position: unset;
      }
    }

    .subtitle {
      font-family: $cui-font-family-source-serif;
    }
  }
}

/* stylelint-disable selector-class-pattern  */
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;

$bt-scale: null;
$bd-scale: null;

@media (max-width: $breakpoint-tablet) {
  $bt-scale: 2;
}

@media (min-width: $breakpoint-desktop) {
  $bd-scale: 4;
}

$scale: $bt-scale or $bd-scale or 4;

$min-top: 4;
$max-top: 140;

$min-right: 4;
$max-right: 54;

$min-bottom: 4;
$max-bottom: 140;

$min-left: 4;
$max-left: 54;

// Top margins

@if $min-top > 0 {
  $unscaled-min-top: round(calc($min-top / $scale));
  $unscaled-max-top: round(calc($max-top / $scale));

  @for $i from $unscaled-min-top through $unscaled-max-top {
    .m-top-#{$i * $scale} {
      margin-top: #{$i * $scale}px;
    }
  }
}

// Right margins

@if $min-right > 0 {
  $unscaled-min-right: round(calc($min-right / $scale));
  $unscaled-max-right: round(calc($max-right / $scale));

  @for $i from $unscaled-min-right through $unscaled-max-right {
    .m-right-#{$i * $scale} {
      margin-right: #{$i * $scale}px;
    }
  }
}

// Bottom margins

@if $min-bottom > 0 {
  $unscaled-min-bottom: round(calc($min-bottom / $scale));
  $unscaled-max-bottom: round(calc($max-bottom / $scale));

  @for $i from $unscaled-min-bottom through $unscaled-max-bottom {
    .m-bottom-#{$i * $scale} {
      margin-bottom: #{$i * $scale}px;
    }
  }
}

// Left margins

@if $min-left > 0 {
  $unscaled-min-left: round(calc($min-left / $scale));
  $unscaled-max-left: round(calc($max-left / $scale));

  @for $i from $unscaled-min-left through $unscaled-max-left {
    .m-left-#{$i * $scale} {
      margin-left: #{$i * $scale}px;
    }
  }
}

.m-reset {
  margin: 0;
}

.m-top-0 {
  margin-top: 0;
}

.m-right-0 {
  margin-right: 0;
}

.m-bottom-0 {
  margin-bottom: 0;
}

.m-left-0 {
  margin-left: 0;
}

@import '../../scss/cui/variables';
@import '../../scss/mixins';

.Toastify__toast-container {
  @include tablet {
    margin-top: 65px;
    width: fit-content;
    max-width: 725px;

  }

  .Toastify__toast {
    margin: 10px;

    @include tablet {
      margin: 0;
    }

    .close-icon {
      width: 30px;
      svg path {
        fill: black;
      }

      @include tablet {
        width: 15px;
      }
    }

    .Toastify__toast-body {
      // Push the toast icon to the top.
      align-items: flex-start;
    }

    &.error {
      border-left: 4px solid $cui-red-500;

      .Toastify__toast-body {
        align-items: center;
        font-family: $cui-font-family-tt-norms;
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;
      }

      .cui-icon {
        color: $cui-red-500;
      }
    }

    &.default {
      .Toastify__toast-body {
        align-items: center;
      }
    }

    &.success {
      border-left: 4px solid $cui-olive-green-color-500;

      .Toastify__toast-body {
        color: $cui-olive-green-color-500;
        font-family: $cui-font-family-tt-norms;
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;
      }
    }
  }
}

@import '../../../../scss/mixins';
@import '../../../../scss/variables';

.cookunity__setting_view {
  font-family: Gilroy, Roboto, sans-serif;
  color: $cookunity-black;

  .setting_view_header {
    display: none;
    padding: 0 20px;
    height: 64px;

    .back {
      padding: 4px 8px;

      img {
        display: block;
      }
    }

    @include mobile {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  & > .title {
    display: none;
    font-size: 28px;
    line-height: 36px;
    padding: 20px 20px 60px 20px;
    margin: 0;

    @include mobile {
      display: block;
    }
  }
}

@import '../_mixins';

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.quarter-width {
  width: 25%;
}

.hidden {
  display: none;
}

.only-mobile {
  display: none;

  @include mobile {
    display: initial;
  }
}

.only-desktop {
  @include mobile {
    display: none !important;;
  }
}

.text-center {
  text-align: center;
}

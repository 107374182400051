@import '../../../../../../scss/variables';
@import '../../../../../../scss/mixins';

.switch-plan-tour-step-modal {
  .switch-plan-tour-step-modal__overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(35, 31, 32, 0.7);
    z-index: $z-index-header-desktop;
  }

  .switch-plan-tour-step-modal__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;

    @include desktop {
      flex-direction: row;
    }
  }

  .content-wrapper {
    position: relative;
    margin-top: 350px;
    background-color: $cui-white-color;
    border-radius: 10px;
    height: fit-content;
    margin-left: 16%;

    @include mobile {
      display: flex;
      justify-content: center;
      margin-right: 10%;
      margin-top: 392px;
    }

    @include tablet {
      display: flex;
      justify-content: center;
      margin-top: 375px;
      width: 450px;
    }

    @include desktop {
      margin-top: 343px;
      width: 343px;
    }

    &::before {
      content: '';
      left: 15%;
      position: absolute;
      top: -19px;
      border: 10px solid transparent;
      border-bottom: 10px solid $cui-white-color;

      @include tablet {
        left: 78%;
      }

      @include desktop {
        left: 55%;
      }
    }
  }

  .content {
    padding: 20px 16px;

    .title {
      font-family: $cui-font-family-source-serif;
      font-style: normal;
      font-weight: $cui-font-bold;
      font-size: $cui-font-xl;
      line-height: $cui-line-height-2xl;
      color: $cui-black-color-500;
    }

    .description {
      font-family: $cui-font-family-tt-norms;
      font-style: normal;
      font-size: $cui-font-sm;
      line-height: $cui-line-height-xl;
      padding-top: 8px;
      color: $cui-black-color-500;
    }

    .cta-container {
      display: flex;
      justify-content: flex-end;
      padding-top: 8px;

      button {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

@import "../../scss/_variables";
@import "../../scss/_mixins";

.view-modal-overlay {
  position: fixed;
  padding: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.48);
  z-index: $z-index-error-modal-overlay;

  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile {
    padding: 0;
  }

  .view-modal-login {
    overflow: hidden;
    border-radius: 8px;
    max-height: 90%;
    padding: 12px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include mobile {
      max-width: 100%;
    }

    header.flex {
      width: 100%;
      text-align: right;
      padding: 24px 32px;
      align-items: center;

      p {
        font-family: 'Gilroy';
        font-size: 20px;
        line-height: 28px;
        color: #231F20;
        border-radius: 200px;
        margin: 0;
      }

      .close-button {
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border-radius: 200px;
        margin-bottom: 5px;
        cursor: pointer;
      }
    }

    .container {
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling: touch;
      flex: 1;
      background: #ffffff;
      max-width: 100%;
    }
  }
}

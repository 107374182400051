$min-column-gap: 2;
$max-column-gap: 32;

$min-row-gap: 2;
$max-row-gap: 32;

// $min-row-template: 1;
// $max-row-template: 30;

// $min-column-template: 1;
// $max-column-template: 4;

$scale: 4;

.column {
  display: flex;
  flex-direction: column;

  &.top {
    justify-content: flex-start;
  }

  &.middle {
    justify-content: center;
  }

  &.bottom {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.start {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }

  &.end {
    align-items: flex-end;
  }

  &.stretch {
    align-items: stretch;
  }

  .self-start {
    align-self: flex-start;
  }

  .self-center {
    align-self: center;
  }

  .self-end {
    align-self: flex-end;
  }

  .self-stretch {
    align-self: stretch;
  }
}

.row {
  display: flex;
  flex-direction: row;

  &.top {
    align-items: flex-start;
  }

  &.middle {
    align-items: center;
  }

  &.bottom {
    align-items: flex-end;
  }

  &.start {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.end {
    justify-content: flex-end;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  &.reverse {
    flex-direction: row-reverse;
  }

  .self-top {
    align-self: flex-start;
  }

  .self-middle {
    align-self: center;
  }

  .self-bottom {
    align-self: flex-end;
  }

  .self-stretch {
    align-self: stretch;
  }
}

.slick-arrow::before {
  content: '';
}

.grid {
  display: grid;

  @if $min-row-gap > 0 {
    $unscaled-min-row-gap: $min-row-gap * $scale;
    $unscaled-max-row-gap: $max-row-gap * $scale;
    @for $i from $min-row-gap through $max-row-gap {
      &.grid-row-gap-#{$i * $scale} {
        grid-row-gap: #{$i * $scale}px;
      }
    }
  }

  @if $min-column-gap > 0 {
    $unscaled-min-column-gap: $min-column-gap * $scale;
    $unscaled-max-column-gap: $max-column-gap * $scale;
    @for $i from $min-column-gap through $max-column-gap {
      &.grid-column-gap-#{$i * $scale} {
        grid-column-gap: #{$i * $scale}px;
      }
    }
  }

  // TODO: evaluate future cost benefit
  // @if $min-column-template > 0 {
  //   $unscaled-min-column-template: $min-column-template * $scale;
  //   $unscaled-max-column-template: $max-column-template * $scale;
  //   @for $i from $min-column-template through $max-column-template {
  //     &.grid-template-columns-#{$i * $scale} {
  //       grid-template-columns: #{$i * $scale} fr #{$i * $scale} fr;
  //     }
  //   }
  // }

  // @if $min-row-template > 0 {
  //   $unscaled-min-row-template: $min-row-template * $scale;
  //   $unscaled-max-row-template: $max-row-template * $scale;
  //   @for $i from $min-row-template through $max-row-template {
  //     @for $x from $min-row-template through $max-row-template {
  //       &.grid-template-rows-#{$i}-#{$x} {
  //         grid-template-rows: #{$i}fr #{$x}fr;
  //       }
  //     }
  //   }
  // }
}

@import '../../scss/mixins';
@import '../../scss/variables';

$SmallModalWidth: 430px;
$CUModalHeight: 589px;
$MediumModalWidth: 624px;
$MediumModalWidthPlus: 644px;
$IntermediateModalWidth: 660px;
$LargeModalWidth: 768px;
$LargeModalWidthPlus: 966px;

.cookunity__new_modal__overlay {
  @include font-family-lato();
  background-color: rgba(35, 31, 32, 0.7);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  outline: 0;
  overflow-y: auto;
  z-index: $z-index-cookunity-modal;

  &.medium_size {
    @media screen and (max-width: $MediumModalWidth) {
      background-color: #ffffff;
    }
  }

  &.large_size {
    @media screen and (max-width: $LargeModalWidth) {
      background-color: #ffffff;
    }
  }

  & > .cookunity__new_modal__content {
    position: relative;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 0 64px rgba(0, 67, 32, 0.16),
      0 16px 32px rgba(0, 67, 32, 0.06), 0 8px 16px rgba(0, 67, 32, 0.04),
      0 4px 8px rgba(0, 67, 32, 0.02);
    margin: 40px auto;
    border-radius: 10px;
    padding: 40px 32px;
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;

    @include mobile {
      padding: 40px 20px;
    }

    &:focus {
      outline: none;
    }

    & > button.cookunity__new_modal__close {
      position: absolute;
      right: 16px;
      top: 16px;
      border: none;
      cursor: pointer;
      background: none;
      padding: 0;
      z-index: 99999;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 100%;

      & > img {
        height: 24px;
        width: 24px;
        vertical-align: middle;
      }

      &:focus {
        outline: none;
      }
    }

    &.medium_size {
      width: $MediumModalWidth;
      @media screen and (max-width: $MediumModalWidth) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        @include mobile {
          padding-top: 64px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 100%;
        }

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    &.large_size {
      width: $LargeModalWidth;
      @media screen and (max-width: $LargeModalWidth) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        @include mobile {
          padding-top: 64px;
          background: rgba(255, 255, 255, 0.5);
          border-radius: 100%;
        }

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    &.plus_size {
      width: $LargeModalWidthPlus;
      @media screen and (max-width: $LargeModalWidthPlus) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    &.medium_plus_size {
      width: $MediumModalWidthPlus;
      @media screen and (max-width: $MediumModalWidthPlus) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    &.small_size {
      width: $SmallModalWidth;
      @media screen and (max-width: $SmallModalWidth) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
          display: flex;
          justify-content: flex-start;
        }
      }
    }

    &.plus_size {
      width: $LargeModalWidthPlus;
      @media screen and (max-width: $LargeModalWidthPlus) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
        }
      }
    }

    &.medium_plus_size {
      width: $MediumModalWidthPlus;
      @media screen and (max-width: $MediumModalWidthPlus) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
        }
      }
    }

    &.intermediate_size {
      min-width: $IntermediateModalWidth;

      @include mobile {
        min-width: auto;
      }

      @media screen and (max-width: $IntermediateModalWidth) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
        }
      }
    }

    &.small_remove_size {
      width: $SmallModalWidth;
      @media screen and (max-width: $SmallModalWidth) {
        width: 100%;
        border-radius: 0;
        margin: 0;
        box-shadow: none;
        padding-top: 84px;

        & > button.cookunity__new_modal__close {
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}

@import '../../../../scss/cui/variables';
@import '../../../../scss/mixins';

.modal-feedback-pause {
  p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    color: #231f20;
    margin: 0;

    b {
      font-weight: bold;
      margin: 0 4px;
    }
  }

  .title {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    margin: -8px 0 24px;
  }

  .pause-sub-title-container {
    p {
      margin: 0 0 24px;
      display: flex;
      font-size: 16px;
    }
  }

  .pause-note-container {
    background: #f5f5f5;
    border-radius: 8px;
    padding: 24px;
    margin: 0 0 32px;

    p {
      font-size: 14px;
    }
  }

  .section-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .pause-modal-button {
    display: flex;
    justify-content: flex-end;

    .cookunity__form_column {
      padding-bottom: 0;
      margin-bottom: -8px;
    }
  }

  .radio-item {
    font-size: 16px;
    margin: 0 0 16px;
    width: 48%;
  }

  .contact-cs {
    padding-left: 30px;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.rebranding {
  .modal-feedback-pause {
    .cookunity__my_account_form {
      margin-top: 0;
    }

    .title {
      color: $cui-black-color;

      @include font-build(
        $cui-font-family-source-serif,
        $cui-font-xl,
        $cui-line-height-3xl,
        $cui-font-bold
      );

      @include mobile {
        margin-bottom: 0;
      }
    }

    .pause-sub-title-container {
      @include mobile {
        margin-top: 1rem;
      }

      p {
        margin-bottom: 1rem;
        color: $cui-black-color;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-xl,
          $cui-font-normal
        );

        @include mobile {
          display: inline;
        }
      }
    }

    .section-container {
      margin-top: 2rem;
      margin-bottom: 0;

      @include mobile {
        margin-bottom: 0;
      }
    }

    .pause-modal-button {
      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .cookunity__button_row {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;

        @include mobile {
          margin-top: 1rem;
        }
      }
    }

    .contact-cs {
      color: $cui-black-color;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-normal
      );
    }
  }
}

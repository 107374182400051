@import '../../../scss/variables';


.dietary-dropdown-container {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: $cookunity-black;

    .dietary-dropdown__placeholder {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $cookunity-gray-2;
    }

    .dietary-dropdown__control {
      min-height: 48px;
      border: 1px solid $cookunity-gray-3;
      border-radius: 4px;

      &--is-focused {
        border: 1px solid $cookunity-gray-3;
        box-shadow: none;
      }

      &:hover {
        border: 1px solid $cookunity-gray-3;
      }

      .dietary-dropdown__value-container {
        font-weight: 400;
      }

      .dietary-dropdown__dropdown-indicator {
        color: $cookunity-black;
        cursor: pointer;


        img {
          width: 80% !important;
          height: 100% !important;
          transform: rotate(0) !important;
        }
      }

      .dietary-dropdown__clear-indicator,
      .dietary-dropdown__indicator-separator {
        display: none;
      }

      .dietary-dropdown__multi-value {
        border-radius: 4px;
        background-color: #ffffff;
        border: 1px solid $cookunity-black;
        height: 32px;
        font-size: 14px;
        line-height: 22px;
        font-family: Gilroy, Roboto, sans-serif;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        font-weight: bold;

        .dietary-dropdown__multi-value__label {
          padding-left: 0;
        }

        .dietary-dropdown__multi-value__remove {
          padding-right: 0;
          cursor: pointer;

          &:hover {
            color: $cookunity-black;
            background-color: #ffffff;
          }
        }
      }
    }

    .dietary-dropdown__menu {
      color: $cookunity-black;
      margin-top: -2px;
      border: 1px solid rgba(0, 0, 0, 0.08);
      box-shadow: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .dietary-dropdown__option {
        font-weight: 300;
      }

      .dietary-dropdown__menu-notice {
        font-weight: 300;
      }

      .dietary-dropdown__option--is-focused,
      .dietary-dropdown__option--is-selected {
        color: $cookunity-black;
        background-color: $cookunity-gray-3;
        cursor: pointer;
      }
    }
  }

$fa-font-path: "~font-awesome/fonts";
// !DEPRECATED. I will change all variables to cookunity-{color}
$green-color: #03a832;
$white-color: #ffffff;
// Colors
$cookunity-white-color: #ffffff;
$cookunity-green-color: #03a832;
$cookunity-lightgreen-color: #79D6A7;
$cookunity-premium-color: #A38247;
$cookunity-lightgray-color: #F5F5F5;
$cookunity-input-focus-color: #E8FCE8;
// z-index
$z-index-date-picker: 2;
$z-index-titles-selection: 2;
$z-index-button-selection-footer-content: 2;
$z-index-buttons-selection-preview: 3;
$z-index-header-desktop: 6;
$z-index-header-mobile: 6;
$z-index-user-menu-icon-mobile: 6;
$z-index-summary-modal-overlay: 10;
$z-index-summary-delivery-modal: 8;
$z-index-summary-delivery: 9;
$z-index-filter-modal-overlay: 10;
$z-index-filter-modal-open: 11;
$z-index-user-menu-open-mobile: 12;

$z-index-error-modal-overlay: 15;
$z-index-error-modal: 16;
$z-index-change-plan-overlay: 17;

$z-index-chat-icon: 100;
$z-index-chat-icon-mobile: 7;

$z-index-cookunity-modal: 200;

$tweet-blue : #71cbf2;
$facebook-blue : #6c87b2;

$items-shadow: 0px 3px 31px -8px rgba(0,0,0,0.75);

$header-height: 80px;

// REBRANDING
$cookunity-black: #231F20;
$cookunity-black-secondary: #000000;
$cookunity-white: #ffffff;
$cookunity-primary-green: #7AD7A7;

$cookunity-secondary-yellow: #FFE06C;
$cookunity-secondary-pink: #FA9CAD;
$cookunity-secondary-light-green: #A9D273;
$cookunity-secondary-blue: #59C6E7;
$cookunity-secondary-dark-green: #47D178;
$cookunity-secondary-red: #EE5744;

$cookunity-gray-1: #767676;
$cookunity-gray-2: #999999;
$cookunity-gray-3: #DDDDDD;
$cookunity-gray-4: #EBEBEB;
$cookunity-gray-5: #F5F5F5;

$cookunity-green: #47D178;
$cookunity-gray: #767676;
$cookunity-light-gray: #DDDDDD;
$cookunity-red: #EE5744;

//SPACES
$space-16px: 16px;
$space-8px: 8px;
$space-16px: 16px;

// FONT size
$h1-mob-fontSize: 28px;
$h2-mob-fontSize: 22px;
$h3-mob-fontSize: 18px;
$h4-mob-fontSize: 16px;
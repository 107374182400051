@import '../../../../scss/mixins';
@import '../../../../scss/cui/variables';

.ReactModal__Content {
  .economic-plan-modal__close-icon {
    @include desktop {
      margin: 0.25rem 1rem;
    }

    path {
      fill: $cui-black-color;
    }
  }

  .close {
    justify-content: flex-end !important;
  }

  .economic-plan-modal__title-container {
    padding-bottom: 1.25rem;
    padding-inline: 2rem;
    border-bottom: 1px solid $cui-black-color-200;

    .economic-plan-modal__title {
      white-space: pre-wrap;

      @include font-build(
        $cui-font-family-source-serif,
        $cui-font-xl,
        $cui-line-height-3xl,
        $cui-font-bold
      );
    }
  }

  .economic-plan-modal__text-container {
    margin-top: 2rem;
    padding-inline: 2rem;

    .economic-plan-modal__text {
      margin-top: 1rem;

      white-space: pre-wrap;

      @include font-build(
        $cui-font-family-tt-norms,
        $cui-font-sm,
        $cui-line-height-xl,
        $cui-font-normal
      );
    }
  }
}

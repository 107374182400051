@import '../../scss/mixins';
@import '../../scss/variables';
@import '../../scss/cui/variables';

.cui-header {
  justify-content: space-between;

  .cui-segment-icon-button {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cui-ally-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cui-header__nav-link {
  color: inherit;
}

.cui-header__extra-delivery {
  color: $cui-yellow-color-600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cui-header__extra-delivery__text {
  text-decoration: underline;

  @media screen and (min-width: $tablet) {
    text-decoration: none;
  }
}

.cui-header__title {
  font-family: $cui-font-family-source-serif;
  font-size: $cui-font-xl;
  line-height: $cui-line-height-2xl;
  font-weight: 700;
}

@import '../../../../scss/mixins';
@import '../../../../scss/cui/variables';

.myPlan__flexMode__container {
  display: flex;
  flex-direction: column;
  margin: 2rem 1rem;

  @include desktop {
    margin: 3.5rem 5rem;
  }

  .myPlan__flexMode__title {
    margin: 0 0 0.75rem 0;

    font-family: $cui-font-family-source-serif;
    font-weight: $cui-font-bold;
    font-size: $cui-font-lg;
    line-height: $cui-line-height-2xl;

    @include desktop {
      margin: 0 0 1.5rem 0;
      font-size: $cui-font-xl;
      line-height: $cui-line-height-3xl;
    }
  }

  .myPlan__flexMode__bodyContainer {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    @include desktop {
      border: 1px solid $cui-black-color-200;
      border-radius: 8px;
      padding: 2rem;
    }

    .myPlan__flexMode__contentContainer {
      display: flex;
      flex-direction: column;

      .myPlan__flexMode__subtitle {
        margin: 0;

        color: $cui-black-color-400;
        font-family: $cui-font-family-tt-norms;
        font-weight: $cui-font-bold;
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;

        @include desktop {
          font-size: $cui-font-base;
          line-height: $cui-line-height-2xl;
        }
      }

      .myPlan__flexMode__description {
        margin: 0;

        white-space: pre-wrap;
        color: $cui-black-color-400;
        font-family: $cui-font-family-tt-norms;
        font-weight: $cui-font-normal;
        font-size: $cui-font-sm;
        line-height: $cui-line-height-xl;
      }
    }

    .myPlan__flexMode__buttonsContainer {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      @include desktop {
        flex-direction: row;
      }

      .divider {
        border-bottom: 0.5px solid $cui-black-color-300;

        @include desktop {
          display: none;
        }
      }
    }
  }

  .compare-plans__tour-highlight {
    z-index: 7;
  }

  .switch-plan__tour-highlight {
    z-index: 7;
  }
}

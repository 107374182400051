@import '../../../../../../scss/mixins';
@import '../../../../../../scss/cui/variables';

.flex-mode__compare-modal__modal-content {
  padding-block-start: 2rem !important;

  @media screen and (min-width: 966px) {
    width: 966px !important;
    padding-block-start: 3rem !important;
  }
}

.ReactModal__Content {
  .flex-mode__compare-modal__close-icon {
    @include desktop {
      margin: 0.25rem 1rem;
    }

    path {
      fill: $cui-black-color;
    }
  }

  .flex-mode__compare-modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include desktop {
      margin-block-start: 0;
    }

    .flex-mode__compare-modal__header-container {
      .flex-mode__compare-modal__title {
        margin: 0;

        white-space: pre-wrap;
        text-align: center;

        @include font-build(
          $cui-font-family-rossanova,
          $cui-font-4xl,
          $cui-line-height-5xl,
          $cui-font-bold
        );

        @include desktop {
          font-size: $cui-font-6xl;
          line-height: $cui-line-height-5xl;
        }
      }

      .flex-mode__compare-modal__description {
        margin-block-start: 0.75rem;

        color: $cui-black-color-400;
        white-space: pre-wrap;
        text-align: center;

        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-xs,
          $cui-line-height-sm,
          $cui-font-normal
        );

        @include desktop {
          font-size: $cui-font-sm;
          line-height: $cui-line-height-xl;
        }
      }
    }

    .flex-mode__compare-modal__plans-container {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      margin-block-start: 1.5rem;

      @include desktop {
        flex-direction: row;
        gap: 2.5rem;
      }

      .flex-mode__compare-modal__plan-container {
        padding: 2.5rem 1rem 1rem;

        border: 1px solid $cui-black-color-200;
        border-top: 12px solid $cui-wine-color-500;
        border-radius: 8px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &--secondary {
          border-top-color: $cui-yellow-color-600;
          background-color: $cui-black-color-100;
        }

        @include desktop {
          width: 374px;
        }

        .flex-mode__compare-modal__plan-description-container {
          .flex-mode__compare-modal__plan-title {
            margin: 0;

            @include font-build(
              $cui-font-family-rossanova,
              $cui-font-5xl,
              $cui-line-height-4xl,
              $cui-font-bold
            );
          }

          .flex-mode__compare-modal__plan-subtitle {
            margin: 0.5rem 0 0;

            @include font-build(
              $cui-font-family-tt-norms,
              $cui-font-base,
              $cui-line-height-2xl,
              $cui-font-bold
            );
          }

          .flex-mode__compare-modal__plan-description {
            margin: 1rem 0 0;

            white-space: pre-wrap;

            @include font-build(
              $cui-font-family-tt-norms,
              $cui-font-xs,
              $cui-line-height-base,
              $cui-font-normal
            );
          }
        }

        .flex-mode__compare-modal__plan-price-container {
          display: flex;
          flex-direction: column;
          margin-block-start: 1rem;

          .flex-mode__compare-modal__plan-price-prefix {
            color: $cui-black-color-300;

            @include font-build(
              $cui-font-family-tt-norms,
              $cui-font-xs,
              $cui-line-height-base,
              $cui-font-normal
            );
          }

          .flex-mode__compare-modal__plan-price {
            display: flex;
            align-items: center;

            @include font-build(
              $cui-font-family-source-serif,
              $cui-font-3xl,
              $cui-line-height-4xl,
              $cui-font-bold
            );
          }

          .flex-mode__compare-modal__plan-price-suffix {
            padding-inline-start: 0.5rem;

            color: $cui-black-color-300;

            @include font-build(
              $cui-font-family-tt-norms,
              $cui-font-base,
              $cui-line-height-2xl,
              $cui-font-normal
            );
          }
        }

        .flex-mode__compare-modal__plan-button-container {
          margin-block-start: 1rem;

          button {
            width: 100%;
          }
        }

        .flex-mode__compare-modal__plan-feature-container {
          margin: 1rem 0 0;
          padding-inline-start: 1rem;
          list-style: none;

          li::before {
            content: '\2022';
            color: $cui-wine-color-500;
            font-weight: bold;
            display: inline-block;
            width: 1rem;
            height: 2rem;
            vertical-align: sub;
            font-size: 2rem;
            margin-inline-start: -1rem;
          }

          .flex-mode__compare-modal__plan-feature {
            color: $cui-black-color-400;
            height: fit-content;

            @include font-build(
              $cui-font-family-tt-norms,
              $cui-font-sm,
              $cui-line-height-xl,
              $cui-font-normal
            );
          }
        }
      }
    }
  }
}

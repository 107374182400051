@import '../../scss/mixins';
@import '../../scss/variables';
@import '../../scss/cui/variables';
@include shimmer_keyframes();

.cookunity__settings_card {
  font-family: Gilroy, Roboto, sans-serif;
  border-radius: 4px;
  border: 1px solid #dddddd;
  background-color: #ffffff;
  color: $cookunity-black;
  position: relative;

  padding: 24px 24px 24px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
  @include mobile {
    margin: 0;
    border: none;
    padding: 16px 16px 60px;
  }

  &.loading {
    & > .title {
      cursor: default;
      background-color: #fafafa;
      @include shimmer();
      width: 40%;
      height: 32px;
      color: transparent;
      user-select: none;
    }

    & > .subtitle {
      display: none;
    }
  }

  .title-section__settings {
    font-family: Gilroy;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #231f20;
    margin: 0 0 8px;
  }

  .sub-section__settings {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #999999;
    margin: 0 0 40px;
  }

  & > .title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 0 20px;

    & > .action_label {
      margin-left: 10px;
      cursor: pointer;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2px;
      text-decoration-line: underline;
      color: $cookunity-primary-green;
      // @include mobile {
      //   display: block;
      // }
    }
  }

  & > .subtitle {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: -16px 0 40px;
  }

  .cookunity__setting_section_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include mobile {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &:not(:last-child) {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $cookunity-gray-3;
    }

    & > .cookunity__setting_section {
      flex-grow: 1;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      @include mobile {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: stretch;
      }

      .rows_container {
        width: 100%;

        & > .section_row {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;

          & > .row_info {
            flex-grow: 1;
            width: 100%;
          }

          font-size: 16px;
          line-height: 24px;

          &.loading {
            .subtitle,
            .title {
              cursor: default;
              background-color: #fafafa;
              color: transparent;
              user-select: none;
              @include shimmer();
            }

            .title {
              width: 40%;
              height: 24px;
            }

            .subtitle {
              width: 30%;
              height: 24px;
            }

            .label {
              display: none;
            }
          }

          &:not(:last-child) {
            margin-bottom: 24px;
          }

          .title_container {
            width: 90%;
            display: flex;
            align-items: center;

            .title {
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .title_new_plan_meals {
              font-weight: 800;
              font-size: 16px;
              line-height: 24px;
              letter-spacing: 0.2px;

              @include mobile {
                font-size: 20px;
                line-height: 28px;
                letter-spacing: 0px;
              }
            }

            .title_new_plan_week {
              font-family: Roboto;
              font-size: 14px;
              line-height: 24px;
              letter-spacing: 0.2px;
              color: $cookunity-gray-2;
            }

            .label {
              background-color: #f5f5f5;
              border-radius: 4px;
              margin: 0 10px;
              font-size: 10px;
              line-height: 18px;
              padding: 2px 8px;
              color: #767676;
              text-transform: uppercase;
            }

            & + .subtitle {
              margin: 8px 0 0 0;
            }
          }

          .subtitle {
            font-family: Roboto, sans-serif;
            margin: 0;
          }

          .subtitle_new_plan_delivery {
            font-size: 14px;
            letter-spacing: 0.2px;
          }

          .subtitle_new_plan_price {
            font-size: 14px;
            letter-spacing: 0.2px;
            font-weight: bold;
          }
        }
      }

      .setting_children {
        width: 100%;

        &.loading {
          cursor: default;
          background-color: #fafafa;
          @include shimmer();
          height: 50px;
          color: transparent;
          user-select: none;
        }
      }

      .action_link {
        position: absolute;
        right: 0;
        top: 0;

        .label {
          color: $cookunity-primary-green;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.2px;
          text-decoration-line: underline;
          cursor: pointer;

          &.disabled {
            cursor: not-allowed;
            color: $cookunity-gray-3;
          }
        }
      }
    }

    & > .cookunity__action_button_container {
      @include mobile {
        width: 100%;
      }

      .action_button {
        @include mobile {
          display: block;
          width: 100%;
          margin-top: 24px;
        }
      }
    }
  }

  .cookunity__add_setting {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .label {
      font-family: Gilroy;
      font-size: 16px;
      font-weight: 800;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
      color: black;
      text-decoration-line: underline;
      margin: 0 0 0 8px;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: $cookunity-gray-3;
      }
    }

    &.loading {
      display: none;
    }

    &.redeem {
      margin-left: 65px;
    }
  }

  .cookunity__remove_setting {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;

    @include mobile {
      justify-content: center;
    }

    .icon {
      cursor: pointer;
      margin-right: 4px;

      &.disabled {
        cursor: not-allowed;
      }
    }

    .label {
      font-size: 16px;
      line-height: 24px;
      color: $cookunity-secondary-red;
      margin: 0 0 0 8px;
      cursor: pointer;
      text-decoration: underline;
      display: flex;

      &.disabled {
        cursor: not-allowed;
        color: $cookunity-gray-3;
      }
    }

    &.loading {
      display: none;
    }
  }

  .cookunity__setting_dropdown {
    position: relative;

    &.disabled > .button {
      cursor: not-allowed;
    }

    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $cookunity-gray-3;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      cursor: pointer;
    }

    & > .list {
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: absolute;
      min-width: 180px;
      right: 0;
      top: 38px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      box-shadow: 0 0 32px rgba(0, 0, 0, 0.04), 0 8px 16px rgba(0, 0, 0, 0.06);
      border-radius: 4px;
      padding: 16px;

      & > .item:not(:last-child) {
        margin-bottom: 16px;
      }

      & > .item {
        width: 100%;
        font-size: 14px;
        line-height: 22px;
        color: $cookunity-black;
        cursor: pointer;

        &.danger {
          color: $cookunity-secondary-red;
        }

        &.disabled {
          color: $cookunity-gray-3;
          cursor: not-allowed;
        }
      }
    }
  }

  .cookunity__form_grid {
    margin-top: 8px;
  }
}

.rebranding {
  .cookunity__settings_card {
    .cookunity__setting_section_container {
      & > .cookunity__setting_section {
        .rows_container {
          & > .section_row {
            .title_container {
              .label {
                background-color: $cui-black-color;
                color: $cui-white-color;
                margin: 0 0 8px 8px;

                @include font-build(
                  $cui-font-family-tt-norms,
                  $cui-font-xxs,
                  $cui-line-height-xs,
                  $cui-font-normal
                );
              }
            }
          }
        }

        .action_link {
          .cui-link {
            &-primary {
              &-disabled {
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .cookunity__add_setting {
      .label {
        @include font-build(
          $cui-font-family-tt-norms,
          $cui-font-sm,
          $cui-line-height-xl,
          $cui-font-bold
        );

        text-decoration-line: none;
        margin: 0;
      }
    }
  }
}

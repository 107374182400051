@import '../../scss/mixins';
@import '../../scss/variables';

.text-image-row-cui {
  section.rebrand.black-background {
    background-color: #000;

    p.rebrand {
      color: #FFFFFF;
    }
  }

  .container-cui {
    margin: 0 auto;
    width: 100%;
    max-width: 1440px;
  }

  .container-cui-row {
    align-items: center;
    display: flex;
    justify-content: space-around;

    @include mobile {
      flex-direction: column-reverse;
    }

    .col-text,
    .col-media {
      max-width: 500px;
      width: 100%;
    }

    .col-text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      @media (min-width: 767px) and (max-width: 1200px) {
        max-width: 40%;
        margin-right: 10px;
      }

      @media (max-width: 1200px) {
        padding: 0 20px;
      }
    }

    .col-media {
      max-height: 267px;

      @include mobile {
        margin-bottom: 32px;
      }

      img {
        height: 100%;
        max-width: 100%;

        @media (min-width: 767px) and (max-width: 1200px) {
          object-fit: cover;
        }

        @include mobile {
          margin: 0 auto;
          object-fit: cover;
        }
      }

      .video-background {
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        max-width: 653px;

        @media (max-width: 920px) {
          position: relative;
          padding: 0;
          width: 100%;
          max-width: 100%;
          height: 303px;
        }

        .video-mask {
          position: absolute;
          top: 0px;
          width: 100%;
          height: 100%;
        }

        video {
          position: absolute;
          left: 3px;
          top: -3px;
          width: 100%;
          height: 100%;
          object-fit: cover;

          @media (max-width: 920px) {
            width: 100%;
          }
        }

        .video-controller {
          position: relative;
          top: calc(100% - 70px);
          z-index: 1;
          margin: 0 auto;
          width: 100%;
          max-width: 1200px;
          padding-left: 15px;
          padding-right: 15px;
          text-align: center;

          @media (max-width: 920px) {
            position: absolute;
            text-align: left;
          }

          img {
            width: 42px;
            cursor: pointer;
            display: inline-block;
          }
        }
      }
    }
  }

  .container-cui-row.reverse {
    flex-direction: row-reverse;

    @include mobile {
      flex-direction: column-reverse;
    }

    .col-text {
      @media (min-width: 767px) and (max-width: 1200px) {
        margin-left: 10px;
      }
    }
  }

  .container-cui-row.reverse-mobile {
    @include mobile {
      flex-direction: column;
    }

    .col-media {
      .video-background {
        @include mobile {
          margin-top: 32px;
        }
      }
    }
  }

  p.rebrand {
    font-family: $cui-font-family-tt-norms;
    font-size: $cui-font-base;
    line-height: $cui-line-height-2xl;
    margin: 0;
  }

  p.rebrand.lead {
    font-family: $cui-font-family-tt-norms;
    font-weight: $cui-font-bold;
    font-size: $cui-font-xl;
    line-height: $cui-line-height-2xl;
    color: $cui-black-color;
  }
}